import * as React from "react"
import Login from "../components/bodies/Login"

const IndexPage = () => {
  return (
    <Login />
  )
}

export default IndexPage

